import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "antd";
import { isMobileOnly } from "react-device-detect";
import Title from "../../components-v2/Base/Title";
import BackgroundSection from "./HeroBG";
import PrimaryCta from "../Buttons/primary-cta";
import SecondaryCta from "../Buttons/secondary-cta";
import HeroStyles from "./HeroStyles";
import Heading from "../../components-v2/Base/HeadingBuilder";

const BHero = (props) => {
  const {
    sectionData,
    heroBg,
    customTop,
    clearBg,
    customHeroContent,
    mobileBg,
    className,
    id,
  } = props;

  return (
    <div id={id || "my-bg"}>
      <BackgroundSection
        className={`hero-b ${className}`}
        imageName={isMobileOnly && mobileBg ? mobileBg : heroBg}
        style={{
          paddingTop: ` ${customTop}`,
          borderRadius: "28px",
        }}
      >
        {!clearBg && <span className="gradient-cover is-radial" />}
        <Row align="middle">
          <Col lg={24} xs={24} className="hero__content">
            {customHeroContent && customHeroContent}
            {sectionData.title && (
              <Heading
                level={1}
                injectionType={2}
                withBlueSymbol
                symbol="."
                className={`hero-b__title text-center whitespace-pre-line mb-6 ${
                  clearBg ? "text-black" : "text-white"
                } ${mobileBg && "mt-48 md:mt-24"}`}
              >
                {sectionData.title}
              </Heading>
            )}
            {sectionData.subtext && (
              <p
                className={`hero-b__para text-center ${
                  clearBg ? "text-black-300" : "text-white"
                }`}
              >
                {sectionData.subtext}
              </p>
            )}
            <Row
              style={{ marginTop: "42px" }}
              className="hero__btns"
              align="middle"
              justify="center"
              wrap={false}
            >
              {sectionData?.PrimaryCta && (
                <PrimaryCta
                  ctaTitle={sectionData.PrimaryCta.ctaTitle}
                  target={sectionData.PrimaryCta.ctaTarget}
                  style={{ marginLeft: 15 }}
                />
              )}

              {sectionData?.SecondaryCta && (
                <SecondaryCta
                  ctaTitle={sectionData.SecondaryCta.ctaTitle}
                  target={sectionData.SecondaryCta.ctaTarget}
                />
              )}
            </Row>
          </Col>
        </Row>

        <HeroStyles />
      </BackgroundSection>
    </div>
  );
};

BHero.propTypes = {
  sectionData: PropTypes.objectOf(PropTypes.any),
  heroBg: PropTypes.string,
  customTop: PropTypes.string,
  clearBg: PropTypes.bool,
  customHeroContent: PropTypes.objectOf(PropTypes.any),
};
BHero.defaultProps = {
  sectionData: "",
  heroBg: "",
  customTop: "125px",
  clearBg: false,
  customHeroContent: "",
};

export default BHero;
