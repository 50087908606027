/* eslint-disable no-useless-concat */
// eslint-disable-next-line import/prefer-default-export
export const vetHero = {
  title: "Veterinarian point-of-sale (POS) software",
  subtext:
    "Bring order to marketing your business, managing appointments, taking payments, and connecting with clients—with one intuitive platform for veterinary clinics.",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/professional-services/veterinarian/demo",
  },
};
export const vetSubHero = {
  title: "Smarter technology—no prescription needed",
  list: [
    "Book more appointments and reduce no-shows",
    "Attract new clients with a strong online presence",
    "Boost client retention to grow revenue",
    "Get funds faster with more ways for clients to pay",
  ],
  subtext:
    "Spend more time attending to your four-legged patients with intuitive technology that makes it easy to run and grow your veterinarian practice.",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/professional-services/veterinarian/demo",
  },
};

export const vetWhiteGlove = {
  mainTitle: "Personalized service & support",
  title: "Let someone care for you for a change",
  content:
    "Every day at the vet clinic brings a new challenge. That's why your back-office systems need to run smoothly. SpotOn is here to help. Our team will work closely with you to customize a simplified software solution that makes life easier for you, your staff, and your clients. And we’ll continue to be there for you with local service and 24/7 technical support.",
  ctaInfoPrimary: {
    ctaTitle: "Learn more",
    ctaTarget: "/professional-services/veterinarian/demo",
  },
};

export const vetLargeFeatureA = {
  featureBlocks: [
    {
      blockTitle: "Save time and increase revenue",
      blockSubTitle:
        "Simplify how you run your business with SpotOn. Our cloud-based platform is thoughtfully designed to help you streamline numerous time-consuming tasks, while also giving you more ways to connect with clients and grow revenue.",
      blockImg: "olo-modified.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/professional-services/veterinarian/demo",
      },
    },
  ],
};

export const vetTrusted = [
  {
    title: "30 – 40%",
    subtext: "Increase in sales with online booking",
  },
  {
    title: "31%",
    subtext: "Increase in sales with repeat clients",
  },
  {
    title: "40%",
    subtext: "decrease in no-shows with automated reminders",
  },
];

export const vetMultiSection = {
  sectionTitle: "Integrated technology for veterinarians",
  featureBlocks: [
    {
      blockTitle:
        "Save time and book more appointments with digital calendaring",
      blockSubTitle:
        "Today’s pet owners expect the ease of booking an appointment with a few taps on their mobile phone. Make it easy for them, while also streamlining your staff scheduling with SpotOn Appointments.",
      blockList: [
        "Online booking from your website and Facebook page, available 24/7",
        "Interactive staff calendaring",
        "Automated appointment reminders",
        "No-show protection",
        "Mailing list builder",
      ],
      blockImg: "vet-a.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/professional-services/veterinarian/demo",
      },
    },
    {
      blockTitle: "Make payments & invoicing easy",
      blockSubTitle:
        "Take payments and send invoices easily, whether you’re seeing clients in the office or making house calls. SpotOn will set you up with a solution that’s quick and easy for your clients, and puts more money in your pocket, with next-day funding and transparent pricing.",
      blockList: [
        "In-person payments, including Apple Pay and Google Pay",
        "Mobile payments",
        "Online payments & deposits",
        "Custom invoices & reminders",
        "Recurring payments",
        "Customer data capture",
      ],
      blockImg: "vet-b.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/professional-services/veterinarian/demo",
      },
    },
    {
      blockTitle: "Stand out with a professional website",
      blockSubTitle:
        "Get a slick new website that’s optimized to help your veterinarian business appear more prominently on Google searches and drive more business. Our team of professionals will build it for you at a do-it-yourself price.",
      blockImg: "vet-c.png",
      blockList: [
        "Custom website design",
        "Web hosting",
        "Custom web address",
        "Self-service dashboard",
        "Optional logo design",
        "Lifetime support",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/professional-services/veterinarian/demo",
      },
    },
    {
      blockTitle: "Market like a pro",
      blockSubTitle:
        "Stay top-of-mind with your clients by sending limited-time deals, promoting new services, and sending seasonal reminders with a few clicks of a button—including right from your mobile phone.",
      blockList: [
        "Prompt new visits and drive traffic",
        "Track campaign performance with real-time analytics",
        "Connect with clients via Facebook, email, and push notifications",
      ],
      blockImg: "vet-d.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/professional-services/veterinarian/demo",
      },
    },
    {
      blockTitle: "Manage your online reputation",
      blockSubTitle:
        "Stay on top of online reviews from sites like Yelp, Facebook, and Google to improve your online reputation and expand your reach. ",
      blockList: [
        "See all your online reviews in one place",
        "Get notified every time you get a new review",
        "Easily shift to review sites to respond",
        "Monitor your reputation as it improves over time",
      ],
      blockImg: "vet-e.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/professional-services/veterinarian/demo",
      },
    },
  ],
};

export const vetCTA = {
  title: "Sign up for your demo.",
  ctaImg: "cta-people.png",
  ctaInfo: {
    ctaTitle: "Learn more",
    ctaTarget: "/professional-services/veterinarian/demo",
  },
};

export const vetTestimonials = {
  title: "What other business owners are saying",
  reviews: [
    {
      quote:
        "From the initial consultation, to ordering, to implementation and integration, the entire process of on-boarding the SpotOn POS System has been seamless and has exceeded our expectations.",
      reviewDate: "Mar 9, 2021",
      reviewer: "Pete M.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn continues to be an invaluable technology partner that listens well and proactively presents innovative solutions to drive our business forward.",
      reviewDate: "Feb 9, 2021",
      reviewer: "Ben P.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn has given me the data access I need to manage my business from a macro as well as micro perspective. The software is easy to access and utilize.",
      reviewDate: "Dec 5, 2020",
      reviewer: "Pamela H.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "Every issue and question has been answered or addressed within hours, if not minutes.I can't say enough good about this company and software.",
      reviewDate: "Feb 24, 2021",
      reviewer: "Brandy R.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "From back office, digital marketing, built our website to take orders off of. The programming is great to allow you to do more tasks with better trained staff.",
      reviewDate: "Dec 1, 2020",
      reviewer: "Marcus O.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
  ],
};

export const vetArticles = {
  title: " Expert articles & advice on growing your business",
  bgText: "Articles.",
};

export const hairCar = {
  // Uncomment the following line and remove
  // the other title once we add more than one vertical
  // title: 'We know health & beauty',
  title: "Run a different type of professional business",
  subtext: "Veterinarians are just one of our specialties.",
  punctuation: "?",
  slides: [
    {
      bg: "accountant.png",
      cardTitle: "Accountant",
      cardContent: "Better software to connect with clients",
      icon: "accountant.png",
      linkTo: "/professional-services/accountants",
    },
    {
      bg: "plumber.png",
      cardTitle: "Plumber",
      cardContent: "Get paid fast, anytime, anywhere",
      icon: "plumber.png",
      linkTo: "/professional-services/plumbers",
    },
  ],
};
