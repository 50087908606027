import React from "react";
import loadable from "@loadable/component";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import BHero from "../../../components/Hero/b-hero";
import {
  vetHero,
  vetSubHero,
  vetWhiteGlove,
  vetLargeFeatureA,
  vetTrusted,
  vetMultiSection,
  vetCTA,
  vetTestimonials,
  vetArticles,
  // vetFaqs,
  hairCar,
} from "../../../data/subverticals/professional/vet";
// import NoVisualsCustom from "../../../components/NoVisuals/NoVisualsCustom";
// import WhiteGlove from "../../../components/WhiteGlove/WhiteGlove";
// import LargeFeatures from "../../../components/LargeFeatures/LargeFeatures";
// import TrustedNumbers from "../../../components/TrustedNumbers/TrustedNumbers";
// import LargeCta from "../../../components/CTAs/LargeCta";
// import TestmonialReviews from "../../../components/TestimonialReviews/TestmonialReviews";
// import BusinessTypes from "../../../components/BusinessTypes/BusinessTypes";
// import Articles from "../../../components/Articles/Articles";
import heroBg from "../../../images/hero-images/hair-salon-white-glove.png";

const NoVisualsCustom = loadable(() =>
  import("../../../components/NoVisuals/NoVisualsCustom")
);
const WhiteGlove = loadable(() =>
  import("../../../components/WhiteGlove/WhiteGlove")
);
const LargeFeatures = loadable(() =>
  import("../../../components/LargeFeatures/LargeFeatures")
);
const TrustedNumbers = loadable(() =>
  import("../../../components/TrustedNumbers/TrustedNumbers")
);
const LargeCta = loadable(() => import("../../../components/CTAs/LargeCta"));
const TestmonialReviews = loadable(() =>
  import("../../../components/TestimonialReviews/TestmonialReviews")
);
const BusinessTypes = loadable(() =>
  import("../../../components/BusinessTypes/BusinessTypes")
);
const Articles = loadable(() =>
  import("../../../components/Articles/Articles")
);

const whiteGloveImport = "vet-white-glove.png";

const vets = () => {
  return (
    <Layout>
      <SEO
        title="Veterinarian POS System | Vet Point of Sale Software | SpotOn "
        description="SpotOn's Veterinary POS software offers integrated payments, invoicing, appointment booking, & marketing solutions to help your vet office become more efficient & streamlined.  Get a demo!"
        image={`https://spoton.com/${heroBg}`}
        cannonical="https://www.spoton.com/professional-services/veterinarian/"
      />
      <BHero sectionData={vetHero} heroBg="vet-hero.png" />
      <NoVisualsCustom sectionData={vetSubHero} complex />
      <WhiteGlove sectionData={vetWhiteGlove} whiteGloveBg={whiteGloveImport} />
      <LargeFeatures
        sectionData={vetLargeFeatureA}
        style={{ marginTop: "128px" }}
      />
      <TrustedNumbers title="By the numbers" numbersArray={vetTrusted} />
      <LargeFeatures
        sectionData={vetMultiSection}
        style={{ marginTop: "128px !important" }}
      />
      <LargeCta sectionData={vetCTA} />
      {/* <SuccessStories sectionData={heroSuccess} slides={hbBTSlides} /> */}
      <TestmonialReviews sectionData={vetTestimonials} />
      <Articles
        sectionData={vetArticles}
        blogFilters={{
          limit: 3,
          include: "tags,authors",
          filter: "tag:small-business",
        }}
        cornerStone={false}
        style={{ marginTop: 60 }}
      />
      <BusinessTypes sectionData={hairCar} />
      {/* <Faqs sectionData={vetFaqs} /> */}
    </Layout>
  );
};

export default vets;
